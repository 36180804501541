@tailwind base;
@tailwind components;
@tailwind utilities;
 
 
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');


 
body {
  margin: 0;
  font-family: 'Roboto', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
 
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.font-roboto-slab {
  font-family: 'Roboto Slab', serif;
}
.font-roboto {
  font-family: 'Roboto', serif;
}
 
.section-title{
  color: #2F2F2F;
  font-size: 38px;
  font-family:'Roboto Slab', serif ;
}
@media  screen and (max-width:800px) {
  .section-title{
 
  font-size: 26px;
 
}
}
/* slide */
.slick-prev:before, .slick-next:before{
color: #c3c2c2 !important;
  font-size: 52px !important;
}
.slick-prev:before, .slick-next:before:hover{
  color:#0976BC !important;
  font-size: 30px !important;
}
.slick-prev{
  left: -55px !important;
}
.slick-list{
  border-radius: 15px;
}
 
/* End slide */
.sidebar {
  /* box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px; */
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}
 
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
 
 
 
.dropdown {
  float: left;
  overflow: hidden;
}
 
 
.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 12px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
 
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-weight: bolder;
  color: #151269;
  border-radius:  12px;
  text-align: left;
}
 
.dropdown-content a:hover {
  background-color: #eee;
}
 
.dropdown:hover .dropdown-content {
  display: block;
}
 
.request_quotation{
  border-radius: 50px;
  color: white !important;
  background-color: #0976BC;
  padding: 10px !important;
}
.request_quotation:hover{
  background-color: #4498cd !important;
}
 
 
@keyframes bg {
  0% {
    background-position:100% 50% ;
  }
  100% {
    background-position:50% 100% ;
  }
}
.svc-creator {
  height: 100vh !important;
}
 
.svc-tabbed-menu-item--selected {
  box-shadow: inset 0px -2px 0px #EB2630 !important;
}
 
.svc-btn {
  background-color: #0976BC !important;
}

.svc-add-new-item-button__text {
  color: white !important;
}

.svc-creator__banner {
  visibility: hidden !important;
}
 
.svc-btn .svc-text {
  color: white !important;
}
 
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}
 
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 
 
/* /////// Animation banner text ///////////// */
 
/* style-table */
.css-pdct74-MuiTablePagination-selectLabel{
  margin-bottom: 0px;
}
.css-1fw5qro-MuiPaper-root{
  box-shadow: none !important;
}
.css-levciy-MuiTablePagination-displayedRows{
  margin-bottom: 0px;
}
.css-15wwp11-MuiTableHead-root{
  background-color:#fafafa ;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
  box-shadow: none !important;
  border: 1px solid #e3e2e2 !important;
}
.css-pdct74-MuiTablePagination-selectLabel{
  display: none;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input{
  border: 1px solid #ccc;
  border-radius: 5px;
}
.css-1ex1afd-MuiTableCell-root{
  padding: 5px 10px !important;
}
.css-h5z9hs-MuiTableCell-root{
    padding: 5px 10px !important;

}

/* end style-table */
 
/* select style */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8px 14px !important;
}
/* /////// Animation banner text ///////////// */
/* style modal  */
.css-tlc64q-MuiPaper-root-MuiDialog-paper{
  border-radius: 10px;
}
/* End style modal  */
/*
.stepper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
 
.step {
  text-align: center;
  position: relative;
}
 
.step-number {
  background-color: #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
}
 
.step-title {
  margin-top: 10px;
}
 
.step.active .step-number {
  background-color: #0976BC;
  color: white;
}
 
 
 
.sv-components-column .sv-components-container-contentBottom {
  width: 100% !important;
  display: flex !important;
  flex-direction: row-reverse !important;
} */
 
 
.sd-action-bar {
  display: flex;
  justify-content: flex-start;
}
 
#sv-nav-prev {
  order: 1;
}
 
#survey_save_current_page {
  order: 2;
}
 
#sv-nav-next {
  order: 3;
}
 
#sv-nav-complete {
  order: 4;
}
 
#sv-nav-preview {
  order: 5;
}
 
.sv-components-column.sv-components-container-contentBottom {
  flex-direction: row-reverse;
}
 
 
.css-1chpzqh{
  /* display: none !important; */
  margin-bottom: 0;
}
.css-rmmij8{
  border: 1px solid #ccc;
  border-radius: 4px;
}
 
.css-qiwgdb{
 padding: 9px 14px !important;}
 
 .css-1wbz3t9{
  background-color: #fafafa !important;
 }
 
 .shadow-md {
      box-shadow: none !important;
    border: 1px solid #ccc !important;
 }
 
 
 
 
 .css-1e6y48t-MuiButtonBase-root-MuiButton-root  {
  text-transform: none !important;
 }
 
 
 
 body #creatorElement {
  /* SurveyJS Creator V1 */
  --primary-color: #7ff07f;
  --secondary-color: #1ab394;
  --primary-text-color: #4a4a4a;
  --secondary-text-color: #a7a7a7;
  --inverted-text-color: #ffffff;
  --primary-hover-color: #6fe06f;
  --selection-border-color: #1ab394;
  --primary-icon-color: #3d4d5d;
  --primary-bg-color: #f8f8f8;
  --secondary-bg-color: #f4f4f4;
  --primary-border-color: #e7eaec;
  --secondary-border-color: #ddd;
  --error-color: #ed5565;
}
body {
  /* SurveyJS Creator V2 */
  --primary: #0976BC;
  --primary-light: rgba(26, 183, 250, 0.1);
  --foreground: #000000;
  --primary-foreground: #ffffff;
  --secondary: #0976BC;
  /* --background: #555555; */
  /* --background-dim: #4d4d4d; */
  /* --background-dim-light: #4d4d4d; */
}
 
.svc-tabbed-menu-item--selected {
box-shadow: inset 0px -2px 0px #0976BC !important;
}
 
.css-13cymwt-control {
  height: 50px !important;
}
 
.css-hlgwow {
  height: 50px !important;
}
 
 
/* Custom styling for the carousel buttons */
.slick-prev, .slick-next {
  color: black; /* Button color */
  z-index: 10; /* Make sure they appear above other content */
  opacity: 1; /* Full opacity */
}
 
.slick-prev:hover, .slick-next:hover {
  color: #000; /* Darken the color on hover */
  opacity: 1;
}
 
.slick-prev:before, .slick-next:before {
  color: black; /* Color for the arrow icon */
  font-size: 30px; /* Increase the size of the arrows */
}
 
.slick-prev {
  left: 10px; /* Adjust left button positioning */
}
 
.slick-next {
  right: 10px; /* Adjust right button positioning */
}
 
.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100% !important;
}
 
.MuiTablePagination-selectLabel {
  display: none;
}
 
.loader {
  margin-right: 10px;
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
 
@keyframes l5 {
  0% {box-shadow: 20px 0 #000, -20px 0 #0002; background: #000}
  33% {box-shadow: 20px 0 #000, -20px 0 #0002; background: #0002}
  66% {box-shadow: 20px 0 #0002, -20px 0 #000; background: #0002}
  100% {box-shadow: 20px 0 #0002, -20px 0 #000; background: #000}
}
 
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  line-height: 2.4375rem;
}
 
/* .carousel-slider {
  border-radius: 18px !important;
} */
 
.carousel .thumbs-wrapper {
  margin: 5px 0px !important;
  padding: 0 10px;
}
 
.carousel .thumbs {
  padding-left: 0rem !important;
  margin: 0px !important;
}
 
.description-card{
  h1,h2,h3,h4,h5,h6{
    line-height: 1;    
 
    strong{
    font-weight: normal;
    font-size: 14px;
  }
  }
 
}
.table {
 font-size: 13px;
}
@media (max-width: 992px) {
  .table {
  display: block !important;
  overflow-x: scroll !important;
}
}
 
 
 .carousel .thumb {
  border : 2px solid #ccc !important ;
  height: 50px;
  width: 50px !important;
  border-radius: 4px !important;
}
 
.carousel .thumb.selected,
.carousel .thumb:hover{
 border : 2px solid #575757 !important ;
}
.slider-wrapper{
  border-bottom:1px solid #ddd
}
 
 
.css-b62m3t-container {
  width: 100%;
}
 
.table-back{
  p{
    margin: 0px !important;
  }
}
 
.ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners{
  min-height: 120px !important;
}
 
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: absolute;
  bottom: -35px;
  background-color: rgb(221, 1, 1);
}
 
.marquee {
  display: inline-block;
  padding-left: 5%;
  animation: marquee 50s linear infinite;
}
 
.marquee p {
  display: inline-block;
  font-size: 1rem;
  color: white;
  margin: 5px  ;
  font-weight: 500;
}
 
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
 
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
