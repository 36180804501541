Carousel {
  /* Style the first row as the header with a blue background */
  table tr:first-child {
    background-color: #0074d9; /* Blue header */
    color: white; /* White text color */
  }

  /* Apply padding and border to all cells in the header row */
  table tr:first-child td,
  table tr:first-child th {
    padding: 10px;
    border: 1px solid #d3d3d3;
    white-space: nowrap !important;
  }

  /* Apply consistent padding and border to all cells */
  table td,
  table th {
    padding: 10px;
    border: 1px solid #d3d3d3;
  }

  /* Add borders to the table itself and set consistent spacing */
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }

  /* Style alternate rows for readability */
  table tr:nth-child(even):not(:first-child) {
    background-color: #f2f2f2; /* Light gray for alternate rows */
  }
}

/* Apply blue background and white text to the table header */
.refactor-table table thead tr {
  background-color: #0074d9 !important;
  color: white !important;
}

/* Ensure first row of the table (header) gets the correct styling */
.refactor-table table tr:first-child {
  background-color: #0074d9; /* Blue header */
  color: white; /* White text color */
}

/* Apply padding and border to all header cells */
.refactor-table table tr:first-child td,
.refactor-table table tr:first-child th {
  padding: 10px;
  border: 1px solid #d3d3d3;
  white-space: nowrap !important;
}

/* Apply consistent padding and border to all cells */
.refactor-table table td,
.refactor-table table th {
  padding: 10px;
  border: 1px solid #d3d3d3;
}

/* Add borders to the table itself */
.refactor-table table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

/* Style alternate rows for better readability */
.refactor-table table tr:nth-child(even):not(:first-child) {
  background-color: #f2f2f2; /* Light gray for alternate rows */
}
